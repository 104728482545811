(function (doc) {

    let fsup = {
        "avif": -1,
        "webp": -1,
    }

    const chSup = (h, t) => {
      if (h == 2) {
        fsup[t] = true
      } else {
        fsup[t] = false
      }
      let fin = 0;
      Object.values(fsup).forEach((v) => {
        if (v !== -1) {
            fin++
        }
      })
      if (fin == Object.values(fsup).length) {
        applySupport()
      }
    }

    const applySupport = () => {
        let d = doc.documentElement
        if (fsup.avif === true) {
            d.classList.add("bg-avif-supported")
        }
        if (fsup.webp === true) {
            d.classList.add("img-webp-supported")
            if (fsup.avif !== true) {
                d.classList.add("bg-webp-supported")
            }
        }
    }
  
    var AVIF = new Image()
    AVIF.onload = AVIF.onerror = function () {
      chSup(AVIF.height, "avif")
    }
    AVIF.src =
      "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A="
  
    var WebP = new Image()
    WebP.onload = WebP.onerror = function () {
      chSup(WebP.height, "webp")
    }
    WebP.src =
      "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA"

  })(
    (window.sandboxApi &&
      window.sandboxApi.parentWindow &&
      window.sandboxApi.parentWindow.document) ||
      document
  );